<!--
 * @Author: yzr
 * @Date: 2020-11-26 16:28:59
 * @LastEditors: yzr
 * @LastEditTime: 2020-11-29 16:34:14
-->
<template>
  <div  @click="openModal">
    <!-- 弹框 -->
    <el-input
      :disabled="disabled"
      :placeholder="'请选择'+(title || '上账产品')"
      :value="name || value"
    ></el-input>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'CheckProductGoods',
  components: {
    SelectConfig,
  },
  props: {
    value: String,
    disabled: Boolean,
    cusName: String,
    title: String,
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      name: this.cusName,
    };
  },
  methods: {
    onGetSelect(val) {
      const name = [];
      val.forEach((v) => {
        name.push(v.productName);
      });
      this.name = name.join(',');
      this.$emit('setProduct', val);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    // 打开弹窗
    openModal() {
      const params = {
        functionCode: 'dms_check_product',
        data: [],
        idKey: 'productCode',
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
  },
};
</script>

<style lang="less" scoped>

</style>
