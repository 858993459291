<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-08 15:51:39
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

import CheckProduct from './components/check_product.vue';

formCreate.component('CheckProduct', CheckProduct);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],
    };
  },

  async mounted() {
    this.rule = await this.getBeforFormRule('dms_min_order_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'productName') {
        rowData.on = {
          ...rowData.on,
          setProduct: (val) => {
            this.setValue({
              productName: val[0].productName,
              productCode: val[0].productCode,
            });
          },
        };
      }
      if (rowData.field === 'productCode') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'cusName') {
        rowData.props = {
          ...rowData.props,
          placeholder: '请选择客户',
          paramsObj: {
            functionCode: 'select-customer',
            data: [],
            idKey: 'customerCode',
          },
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => {
            this.setValue({
              cusName: val[0].customerName,
              cusCode: val[0].customerCode,
            });
          },
        };
      }
      if (rowData.field === 'orgName') {
        rowData.props = {
          ...rowData.props,
          placeholder: '请选择组织',
          paramsObj: {
            functionCode: 'select-org',
            data: [],
            idKey: 'orgCode',
          },
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => {
            this.setValue({
              orgCode: val[0].orgCode,
              orgName: val[0].orgName,
            });
          },
        };
      }
      if (rowData.field === 'terminalName') {
        rowData.props = {
          ...rowData.props,
          placeholder: '请选择终端',
          paramsObj: {
            functionCode: 'select-terminal',
            data: [],
            idKey: 'terminalCode',
          },
        };
        rowData.on = {
          ...rowData.on,
          setCheckInfo: (val) => {
            this.setValue({
              terminalCode: val[0].terminalCode,
              terminalName: val[0].terminalName,
            });
          },
        };
      }
      if (rowData.field === 'dimensionType') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.setValue({
              cusCode: '',
              cusName: '',
              orgCode: '',
              orgName: '',
              terminalCode: '',
              terminalName: '',
            });
            this.hiddenFields(true, ['cusName', 'orgName', 'terminalName']);
            if (val === '0') {
              this.hiddenFields(false, ['orgName']);
            }
            if (val === '1') {
              this.hiddenFields(false, ['cusName']);
            }
            if (val === '2') {
              this.hiddenFields(false, ['terminalName']);
            }
          },
        };
      }
      return rowData;
    });

    this.reload(this.rule);
    this.hiddenFields(true, ['cusName', 'orgName', 'terminalName']);
    if (this.formConfig.id) {
      this.getData();
    } else {
      this.getDictSelect();
    }
  },

  methods: {
    // 获取数据字典
    getDictSelect() {
      request.post('/mdm/mdmdictdata/batchDictSelect', ['product_sale_unit']).then((res) => {
        if (res.success) {
          this.setValue({
            unitName: res.result.product_sale_unit[0].dictCode,
          });
        }
      });
    },
    getData() {
      const { dimensionType } = this.formConfig;
      if (dimensionType === '0') {
        this.hiddenFields(false, ['orgName']);
      }
      if (dimensionType === '1') {
        this.hiddenFields(false, ['cusName']);
      }
      if (dimensionType === '2') {
        this.hiddenFields(false, ['terminalName']);
      }
      this.setValue(this.formConfig);
    },
    // 提交表单
    submit() {
      if (!this.getFormData()) return false;
      let params = null;
      let path = '/dms/m/minBuyNumOfProduct/add';
      if (this.formConfig.id) {
        path = '/dms/m/minBuyNumOfProduct/update';
        params = {
          ...this.getFormData(),
        };
      } else {
        params = [{
          ...this.getFormData(),
        }];
      }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
