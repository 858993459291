<!--
 * @Author: yzr
 * @Date: 2020-11-24 10:02:48
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-17 15:32:21
-->
<script>
import TablePage from '@/found/components/table_page';
import Form from '../form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      params: { },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('dms_min_order_table');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = row;
        this.openModal();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.openModal();
      }
      return true;
    },
  },
};
</script>
